{
  "name": "@react-spectrum/provider",
  "version": "3.2.1",
  "description": "Spectrum UI components in React",
  "license": "Apache-2.0",
  "main": "dist/main.js",
  "module": "dist/module.js",
  "types": "dist/types.d.ts",
  "source": "src/index.ts",
  "files": [
    "dist",
    "src"
  ],
  "sideEffects": [
    "*.css"
  ],
  "targets": {
    "main": {
      "includeNodeModules": [
        "@adobe/spectrum-css-temp"
      ]
    },
    "module": {
      "includeNodeModules": [
        "@adobe/spectrum-css-temp"
      ]
    }
  },
  "repository": {
    "type": "git",
    "url": "https://github.com/adobe/react-spectrum"
  },
  "dependencies": {
    "@babel/runtime": "^7.6.2",
    "@react-aria/i18n": "^3.3.1",
    "@react-aria/overlays": "^3.7.0",
    "@react-aria/utils": "^3.8.1",
    "@react-spectrum/utils": "^3.6.0",
    "@react-types/provider": "^3.3.1",
    "@react-types/shared": "^3.7.1",
    "clsx": "^1.1.1"
  },
  "devDependencies": {
    "@adobe/spectrum-css-temp": "3.0.0-alpha.1"
  },
  "peerDependencies": {
    "react": "^16.8.0 || ^17.0.0-rc.1"
  },
  "publishConfig": {
    "access": "public"
  }
}
